/* eslint-disable */
<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="header">
          <div v-if="ismobile" style="width:45px;"><van-icon size="28" name="bars" color="#666666" @click="showlefttab" /></div>
          <img src="../assets/logo.png" alt="" style="cursor: pointer">
          <h1 style="cursor: pointer">dic.cool</h1>
          <div v-if="!ismobile" class="tab-box">
            <p class="tab-item tab-item-active" @click="toclick('/')">首页</p>
            <p class="tab-item" @click="toclick('/apk')">安装教程</p>
            <p class="tab-item" @click="toclick('/about')">使用教程</p>
            <p class="tab-item" @click="toclick('/feedback')">问题反馈</p>
          </div>
        </div>
      </el-header>
      <el-container v-if="!ismobile" class="container">
        <el-card class="card">
          <div class="head">
            <p class="title">酷学术，智能学术研究新体验</p>
            <div class="install">
              <p class="sub-title">免费安装到浏览器</p>
              <div class="item-box">
                <div
                  :class="['item', browserId === item.id ? 'item-active' : '']"
                  v-for="item in installList"
                  :key="item.id"
                  @click="handleInstall(item)"
                >
                  <img v-if="item.id === 1" src="../assets/chrome.png" alt="">
                  <img v-else-if="item.id === 2" src="../assets/edge.png" alt="">
                  <img v-else-if="item.id === 3" src="../assets/firefox.png" alt="">
                  <img v-else-if="item.id === 4" src="../assets/other.png" alt="">
                  <img v-else src="../assets/crx.png" alt="">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="sidebar">
              <!-- <div class="desc">
                酷学术整合了智能推荐、在线翻译和文献整理等多种功能，旨在为用户提供一个高效、便捷的学术研究辅助工具
              </div> -->
              <div class="item-box">
                <div
                  :class="['item', movindex == index ? 'item-active' : '']"
                  v-for="(item, index) in sidebarList"
                  :key="item.id"
                  @click="movclick(index)"
                >
                  <p class="title-box">
                    <img v-if="item.id === 1 && movindex != 0" src="../assets/icon (2).png" alt="">
                    <img v-if="item.id === 1 && movindex == 0" src="../assets/icon_2.png" alt="">
                    <img v-if="item.id === 2 && movindex != 1" src="../assets/icon (1).png" alt="">
                    <img v-if="item.id === 2 && movindex == 1" src="../assets/icon_1.png" alt="">
                    <img v-if="item.id === 3 && movindex != 2" src="../assets/icon (3).png" alt="">
                    <img v-if="item.id === 3 && movindex == 2" src="../assets/icon_3.png" alt="">
                    <img v-if="item.id === 4 && movindex != 3" src="../assets/icon (5).png" alt="">
                    <img v-if="item.id === 4 && movindex == 3" src="../assets/icon_5.png" alt="">
                    {{ item.title }}
                  </p>
                  <div class="explain">
                    <p>{{ item.desc1 }}</p>
                    <p>{{ item.desc2 }}</p>
                  </div>
                </div>
                <div class="about">
                  <p @click="toarey" class="toarey">隐私政策</p>
                  <p @click="aboutUs" class="toarey">关于我们</p>
                </div>
              </div>
              <!-- <img class="img" src="../assets/infoins.png" alt=""> -->
            </div>
            <div class="innerright">
              <video width="100%" height="100%" :controls="false" autoplay loop ref="video" muted>
                <source :src="videolist[movindex].src" type="video/mp4">
                您的浏览器不支持 HTML5 video 标签。
              </video>
            </div>
          </div>
          <div style="background: #F6E7FB;padding:5px 20px;display: flex;justify-content: space-between;align-items: center;">
            <span style="display: flex;align-items: center;font-size: 14px;color: #BD46E2;font-weight: 600;"><img style="width:35px;" src="../assets/公司LOGO.png" alt=""> 北京盈科千信科技有限公司</span>
            <span style="display: flex;align-items: center;font-size: 14px;color: #BD46E2;font-weight: 600;">地址：北京市海淀区中关村南大街48号</span>
            <span style="display: flex;align-items: center;font-size: 14px;color: #BD46E2;font-weight: 600;">邮箱：service@newacademic.net</span>
            <span style="display: flex;align-items: center;font-size: 14px;color: #BD46E2;font-weight: 600;">Copyright © 2024 <img src="../assets/copyright.png" alt=""> 京ICP备15054044号-2</span>
          </div>
        </el-card>
      </el-container>

      <el-container class="mobile" style="background: #FFFFFF;min-height:calc(100vh - 100px);padding: 15px;" v-else>
        <div style="background: #fdf8ff;padding: 20px;border-radius: 10px;">
          <div class="head">
            <div class="explain">
              <p>使用酷学术</p>
              <p>将更好的提高您的学术研究效率</p>
            </div>
            <!-- <div class="Minnerleft_tow">
              酷学术整合了智能推荐、在线翻译和文献整理等多种功能，旨在为用户提供一个高效、便捷的学术研究辅助工具
            </div> -->
          </div>
          <div class="install">
            <p class="sub-title">免费安装到浏览器</p>
            <div class="item-box">
              <div
                class="item"
                v-for="item in installList"
                :key="item.id"
                @click="handleInstall(item)"
              >
                <img v-if="item.id === 1" src="../assets/chrome.png" alt="">
                <img v-else-if="item.id === 2" src="../assets/edge.png" alt="">
                <img v-else-if="item.id === 3" src="../assets/firefox.png" alt="">
                <img v-else-if="item.id === 4" src="../assets/other.png" alt="">
                <img v-else src="../assets/crx.png" alt="">
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="video-box">
            <video webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page" width="100%" height="100%" style="border-radius: 5px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" :controls="false" autoplay loop ref="video" muted>
              <source :src="videolist[0].src" type="video/mp4">
              您的浏览器不支持 HTML5 video 标签。
            </video>
            <div :class="'Minnerleft_li'">
              <div class="Minnertitle">
                <img src="../assets/icon (2).png" alt=""><span>智能文献推荐</span>
              </div>
                <div class="Minnertext">
                  <p>根据当前浏览文献页面，智能推荐相关的中英文献；</p>
                  <p>帮您从海量信息中快速定位相关资料，为您的研究工作添翼。</p>
                </div>
            </div>
          </div>
          <div class="video-box">
            <video webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page" width="100%" height="100%" style="border-radius: 5px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" :controls="false" autoplay loop ref="video" muted>
              <source :src="videolist[1].src" type="video/mp4">
              您的浏览器不支持 HTML5 video 标签。
            </video>
            <div :class="'Minnerleft_li'">
              <div class="Minnertitle">
                <img src="../assets/icon (1).png" alt=""><span>中英实时翻译</span>
              </div>
                <div class="Minnertext">
                  <p>支持在线网页的直接翻译；</p>
                  <p>将您在线网页、文档，按对应输出语言，直接翻译原页面中。</p>
                </div>
            </div>
          </div>
          <div class="video-box">
            <video webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page" width="100%" height="100%" style="border-radius: 5px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" :controls="false" autoplay loop ref="video" muted>
              <source :src="videolist[2].src" type="video/mp4">
              您的浏览器不支持 HTML5 video 标签。
            </video>
            <div :class="'Minnerleft_li'">
              <div class="Minnertitle">
                <img src="../assets/icon (3).png" alt=""><span>导出引用题录</span>
              </div>
                <div class="Minnertext">
                  <p>支持多种国内外主流引文格式；</p>
                  <p>将您浏览的文献，按相应格式导出题录，简化文献整理流程。</p>
                </div>
              </div>
          </div>

          <div class="video-box">
            <video webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page" width="100%" height="100%" style="border-radius: 5px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" :controls="false" autoplay loop ref="video" muted>
              <source :src="videolist[3].src" type="video/mp4">
              您的浏览器不支持 HTML5 video 标签。
            </video>
            <div :class="'Minnerleft_li'">
              <div class="Minnertitle">
                <img src="../assets/icon (5).png" alt=""><span>智能AI问答</span>
              </div>
                <div class="Minnertext">
                  <p>集合多种AI智能服务，为您提供高效智力支持;</p>
                  <p>将您在线浏览的页面，提供实时问答与页面的快速总结。</p>
                </div>
              </div>
          </div>

          <div class="about">
            <p @click="toarey" class="toarey">隐私政策</p>
            <p @click="aboutUs" class="toarey">关于我们</p>
          </div>
          <!-- <img style="width:50%;margin-top: 20px;" src="../assets/infoins.png" alt=""> -->

          <div style="background: #F6E7FB;display: flex;align-items: flex-start;padding: 10px;border-radius: 10px;margin-top: 20px">
            <img style="width: 35px;" src="../assets/公司LOGO.png" alt="">
            <div style="font-size: 14px;font-weight: 600;color: #BD46E2;display: flex;flex-direction: column;">
              <span style="line-height: 2;">北京盈科千信科技有限公司</span>
              <span style="line-height: 2;">地址：北京市海淀区中关村南大街48号</span>
              <span style="line-height: 2;">邮箱：service@newacademic.net</span>
              <span style="line-height: 2;">Copyright © 2024 <img src="../assets/copyright.png" alt=""> 京ICP备15054044号-2</span>
            </div>
          </div>
        </div>
      </el-container>
    </el-container> 
    <el-drawer
      title="我是标题"
      :visible.sync="lfettabloading"
      direction="ltr"
      size="70%"
      :with-header="false"
      :before-close="handleClose1"
    >
      <div class="drawer">
        <div class="title">
          <img src="../assets/logo.png" alt="">
          <h1>dic.cool</h1>
        </div>
        <p class="tab-item tab-item-active" @click="toclick('/')">首页</p>
        <p class="tab-item" @click="toclick('/apk')">安装教程</p>
        <p class="tab-item" @click="toclick('/about')">使用教程</p>
        <p class="tab-item" @click="toclick('/feedback')">问题反馈</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {
      movindex: 0,
      videolist:[
        {
          src: require('../assets/move/智能文献推荐.mp4'),
          show: true
        },
        {
          src:  require('../assets/move/网页翻译.mp4'),
          show: false
        },
        {
          src: require('../assets/move/导出引用题录.mp4'),
          show: false
        },
        {
          src: require('../assets/move/网页问答.mp4'),
          show: false
        }
      ],
      ismobile: false,
      lfettabloading:false,
      browserId: null, // 浏览器版本对应id
       
      installList: [{
        id: 1,
        text: 'Chrome 扩展',
        url: 'https://chromewebstore.google.com/detail/%E9%85%B7%E5%AD%A6%E6%9C%AF/angphjdghkpkjdbljdfabiphhkolenbn?hl=zh-CN&utm_source=ext_sidebar'
      }, {
        id: 2,
        text: 'Edge 扩展',
        url: 'https://microsoftedge.microsoft.com/addons/detail/%E9%85%B7%E5%AD%A6%E6%9C%AF/ddbbchndnifnljpahafeoodeliphgond'
      },
      {
        id: 3,
        text: 'FireFox 扩展',
        url: 'https://addons.mozilla.org/addon/%E9%85%B7%E5%AD%A6%E6%9C%AF/'
      }, 
      {
        id: 4,
        text: '其它浏览器 扩展',
        url: 'https://ext.se.360.cn/#/extension-detail?id=ofabpngdifhcjfijekcmhlbkkplhijlj'
      }, {
        id: 5,
        text: 'zip 安装包',
        url: 'crx'
      }],
      sidebarList: [{
        id: 1,
        title: '智能文献推荐',
        desc1: '根据当前浏览文献页面，智能推荐相关的中英文献;',
        desc2: '帮您从海量信息中快速定位相关资料，为您的研究工作添翼。'
      }, {
        id: 2,
        title: '中英实时翻译',
        desc1: '支持在线网页的直接翻译;',
        desc2: '将您在线网页、文档，按对应输出语言，直接翻译原页面中。',
      }, {
        id: 3,
        title: '导出引用题录',
        desc1: '支持多种国内外主流引文格式;',
        desc2: '将您浏览的文献，按相应格式导出题录，简化文献整理流程。'
      },{
        id: 4,
        title: '智能AI问答',
        desc1: '集合多种AI智能服务，为您提供高效智力支持;',
        desc2: '将您在线浏览的页面，提供实时问答与页面的快速总结。'
      }]
    }
  },
  created(){
    // 通过ua判断浏览器是否是手机端
    var ua = navigator.userAgent;
    if(ua.indexOf('Mobile') != -1){
      this.ismobile = true;
    }
    if (ua.match(/Edg\/([\d.]+)/)) {
      this.browserId = 2
    } else if (ua.match(/Firefox\/([\d.]+)/)) {
      this.browserId = 3
    } else if (ua.match(/Chrome\/([\d.]+)/)) {
      this.browserId = 1
    }
    // 获取当前浏览器拼接的url参数
    let channel = this.getChannel()
    if(channel) {
      this.$http({
        method: 'get',
        url: 'https://www.newacademic.online/diccool/DIC-COOL-SERVICE-V3/eventTracking/promotionChannel',
        params: {
          channel
        }
      }).then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err)
      })
    }
  },
  methods: {
    getChannel() {
      let url = window.location.href
      let urlArr = url.split('?')
      if(urlArr.length > 1) {
        let channel = urlArr[1].split('=')[0]
        let value = urlArr[1].split('=')[1]
        if(channel === 'channel') {
          return value
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    toarey() {
      this.$router.push('/privacy')
    },
    aboutUs() {
      this.$router.push('/aboutUs')
    },
    showlefttab(){
      this.lfettabloading = true
    },
    handleClose1(){
      this.lfettabloading = false
    },
    movclick(index){
      this.movindex = index
      this.$refs.video.load()
    },
    toclick(path){
      if(this.$route.path === path) return
      this.$router.push(path)
    },
    download(){
      let params = {
        method: 'get',
        url: 'https://www.newacademic.online/diccool/DIC-COOL-SERVICE-V3/eventTracking/downloadlogs'
      }
      let channel = this.getChannel()
      if(channel) {
        params.params = {
          channel
        }
      }
      this.$http(params).then(res => {
        console.log(res)
        window.open('http://addons.dic.cool/download/diccool.zip')
        this.$router.push('/apk')
      }).catch(err => {
        console.log(err)
      })
    },
    // 安装方式
    handleInstall(item) {
      if(item.url === 'crx') {
        this.download()
      } else {
        let channel = this.getChannel()
        if(channel) {
          let obj = {
            1: 'chrome',
            2: 'edge',
            3: 'firefox',
            4: 'other'
          }
          this.$http({
            method: 'get',
            url: 'https://www.newacademic.online/diccool/DIC-COOL-SERVICE-V3/eventTracking/openOtherStore?channel=' + channel + '&type=' + obj[item.id]
          })
        }
        window.open(item.url)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  .header {
    .tab-box {
      display: flex;
      .tab-item {
        font-size: 16px;
        margin-left: 30px;
        padding: 4px 0;
        box-sizing: border-box;
        cursor: pointer;
        border-bottom: 3px solid #FFF;
      }
      .tab-item-active {
        border-bottom: 3px solid #a411d1;
      }
    } 
  }
  /** PC 内容区 */
  .container {
    background: #f7fafd;
    min-height: calc(100vh - 120px);
    padding: 10px 0;
    .card {
      min-height: calc(100vh - 100px);
      overflow-y: hidden;
      width: 100%;
      .head {
        padding-top: 15px;
        background: #fdf8ff;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #a411d1;
          margin-bottom: 12px;
          text-align: center;
        }
        .install {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          background: #FFF;
          padding: 20px 20px;
          max-width: 1000px;
          margin: 0 auto;
          border-radius: 4px;
          .sub-title {
            font-size: 22px;
            color: #333;
            font-weight: 600;
            margin-right: 20px;
          }
          .item-box {
            display: flex;
            gap: 20px;
            .item {
              padding: 10px 16px;
              border-radius: 4px;
              color: #333;
              border: 2px solid #FBF5FE;
              font-size: 20px;
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 6px;
              img {
                width: 20px;
                height: 20px;
              }
            }
            .item-active {
              // background: #F6E7FB;
              border: 1px solid #A411D1;
            }
            .item:hover {
              background: #F6E7FB;
              border: 1px solid #A411D1;
            }
          }
        }
      }
      .content {
        background: #fdf8ff;
        padding: 16px 10%;
        display: flex;
        flex-wrap: wrap;
        gap: 5%;
        min-height: calc(100vh - 360px);
        .sidebar {
          width: 32%;
          display: flex;
          align-items: center;
          .desc {
            font-size: 15px;
            color: #666;
            font-weight: 600;
          }
          .item-box {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .item {
              padding: 10px 12px;
              border-radius: 4px;
              background: #F6E7FB;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              font-size: 15px;
              color: #A830D5;
              font-weight: 600;
              cursor: pointer;
              .title-box {
                display: flex;
                align-items: center;
                gap: 6px;
                img {
                  width: 28px;
                  height: 28px;
                }
              }
              .explain {
                font-size: 14px;
                margin-top: 10px;
              }
            }
            .item-active {
              background: #A411D1 !important;
              color: #FFF !important;
            }
            .about {
              display: flex;
              gap: 20px;
            }
          }
          .img {
            width: 200px;
            margin-top: 16px;
          }
        }
        .innerright {
          width: 63%;
          padding: 0 20px;
          position: relative;
          display: flex;
          align-items: center;
          video {
            height: auto;
            border-radius: 5px;
            margin-bottom: 15px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }
        }
      }
    }
  }
  /** 移动端内容区 */
  .mobile {
    .head {
      padding: 0 20px;
      .explain {
        font-size: 28px;
        font-weight: 600;
        color: #a411d1;
        letter-spacing: 2px;
        p {
          margin-bottom: 8px;
        }
      }
      .Minnerleft_tow {
        margin-top: 10px;
        font-size: 15px;
        width: 100%;
        color: #666;
      }
    }
    .install {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      background: #FFF;
      padding: 20px;
      border-radius: 4px;
      .sub-title {
        font-size: 20px;
        color: #333;
        font-weight: 600;
        margin-right: 20px;
      }
      .item-box {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .item {
          padding: 10px 32px;
          border-radius: 4px;
          color: #333;
          font-weight: 600;
          border: 2px solid #FBF5FE;
          font-size: 18px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 6px;
          img {
            width: 18px;
            height: 18px;
          }
        }
        .item:hover {
          background: #F6E7FB;
        }
      }
    }
    .video-box {
      margin-top: 16px;
      background: #F3E3F8;
      padding: 20px;
      border-radius: 10px;
    }
    .about {
      margin-top: 10px;
      display: flex;
      gap: 20px;
    }
  }

  .drawer {
    padding: 20px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
    .tab-item {
      padding: 4px 10px;
      margin-bottom: 10px;
    }
    .tab-item-active {
      background:#F1F1F1;
      color:#a411d1;
      border-radius: 5px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}

.header{
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  padding: 10px 0;
}
.header>img{
    width: 40px;
}
.header>h1{
  font-size: 24px;
  margin-left: 10px;
}
.header>p{
  font-size: 16px;
  margin-left: 30px;
  cursor: pointer;
}
.innerleft{
  width: 40%;
}
.innerright{
  width: 60%;
  padding: 0 20px;
  position: relative;
}
.innerright>video{
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.innerleft_top{
  display: flex;
  margin-bottom: 10px;
}
.innerleft_top > span{
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
  color:#a411d1;
}
.innerleft_tow{
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  color: #999;
}
.innerleft_li1>div>span{
  font-weight: 600;
  font-size: 18px;
  color: #BD46E2;
}
.innerleft_li1>img{
  margin-right: 15px;

}
.innerleft_li1>div>p{
  margin: 5px 0;
  font-size: 14px;
  color: #C35FE2;
}
.innerleft_li1:hover span{
  color: #a411d1;
}
.innerleft_li1:hover p{
  color: #a411d1;
}
.innerleft_li>div>span{
  font-weight: 600;
  font-size: 18px;
}
.innerleft_li>img{
  margin-right: 15px;

}
.innerleft_li>div>p{
  margin: 5px 0;
  font-size: 14px;
}
.Minnerleft_li{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.Minnertitle{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #a411d1;
  font-size: 14px;
  img {
    width: 28px;
    height: 28px;
  }
}
.Minnertitle>span{
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: #a411d1;
}
.Minnertext{
  font-size: 14px;
  color: #a411d1;

}
.innerleft_ul>li{
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px;
  list-style: none;
  border-radius: 5px;
  background: #f6e7fb;
  color: #a411d1;
  display: flex;
  align-items: flex-start;
}
.active{
  border:1px solid #a411d1;
}

.toarey{
  color: #999;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}
</style>
/* eslint-disable */